import { getRouteUrl } from './lib'

/**
 * 建筑汇首页
 */
export function home(locale) {
  return getRouteUrl('/productspec', null, locale)
}

/**
 * 产品搜索
 */
export function search(pathname, locale) {
  const slug = pathname || 'page-1'
  return getRouteUrl('/productspec/products/{slug}', { slug }, locale)
}

/**
 * 产品详情
 */
export function product(slug, locale) {
  return getRouteUrl('/productspec/product/{slug}', { slug }, locale)
}

/**
 * 案例搜索
 */
export function cases(pathname, locale) {
  const slug = pathname || 'page-1'
  return getRouteUrl('/productspec/cases/{slug}', { slug }, locale)
}

/**
 * 案例详情
 */
export function caseDetail(slug, locale) {
  return getRouteUrl('/productspec/case/{slug}', { slug }, locale)
}

/**
 * 活动详情
 */
export function activity(slug, locale) {
  return getRouteUrl('/productspec/activity/{slug}', { slug }, locale)
}

/**
 * 公司详情
 */
export function company(slug, locale) {
  return getRouteUrl('/productspec/company/{slug}', { slug }, locale)
}

/**
 * 公开课列表
 */
export function lives(pathname, locale) {
  const slug = pathname || 'page-1'
  return getRouteUrl('/productspec/lives/{slug}', { slug }, locale)
}
