/**
 * 参数配置说明
 * 
 * array: 返回一个数组
 * object: 返回一个对象
 * numberic: 返回的值为Number
 * bool: 返回的值为Boolean
 * prop: 用于生成接口所需要参数时的字段名称配置
 * apiDisabled getApiSearchParam 不会返回值
 * empty 值是否为空，正常情况下，空值会被过滤，加了此属性会返回值
 * 
 * 
 * eg: list_region-auckland-35_street-queue-st-1142_newhomes_
 *     _bathrooms-2_carspaces-2_bedrooms-2
 *     _filters-bern-2-barn-2-pkn-2
 * 
 * # 什么也不配置，直接返回字符串
 * {filter: {}} // 'prn-12,13-bern-2-barn-2-pkn-2'
 * 
 * # 返回一个数组，里面包含字符串
 * {bathrooms: { array: true }} // ['2']
 * 
 * # 返回一个数组，里面包含对象, 对象字段对应的值为String
 * {street: { array: true, object: true }}  // {name: 'queue-st', value: '1142'}
 * 
 * # 返回一个数组，里面包含对象, value的值为数字
 * {region: { array: true, object: true, numberic: true }} // [{name: 'auckland', value: 35}]
 * 
 * # 布尔类型配置
 * {newhomes: {bool: true}} // {newhomes: true}
 * 
 */

import { forEach, isArray } from 'lodash-es'

/**
 * 解析字段串为数组
 *  - open-home-20231125,20231126-price-method-1
 *  - [
 *      { label: 'open-home', value: '20231125,20231126' },
 *      { label: 'price-method', value: '1' }
 *    ]
 */
export function extractValues(inputString) {
  const regex = /([a-zA-Z][a-zA-Z-]*)-([\d,]+)/g
  const matches = []
  let match

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(inputString)) !== null) {
    const label = match[1]
    const value = match[2]
    matches.push({ label, value })
  }

  return matches
}

function filterSetter({ value, prop, itemSeprator }) {
  const tmp = []
  forEach(
    isArray(value) ? value : [],
    (item) => tmp.push(`${item.label}${itemSeprator}${item.value}`)
  )
  return `${prop}${itemSeprator}${tmp.join(itemSeprator)}`
}

/**
 * 房源搜索
 */
export const houseSearchRules = {
  $sorter: [
    'list', 'list1', 'map', 
    { groupName: 'with', items: ['bedroom', 'bathroom', 'carspace', 'price'] },
    { groupName: 'in', items: ['region', 'district', 'suburb'] },
  ], // new v2
  list: { array: true, apiDisabled: true, empty: true, }, // new v2
  list1: { array: true, apiDisabled: true, empty: true }, // new v2
  map: { array: true, apiDisabled: true, empty: true }, // new v2
  region: { array: true, object: true, numberic: true, prop: 'regionId' },
  district: { array: true, object: true, numberic: true, prop: 'districtId' },
  suburb: { array: true, object: true, numberic: true, prop: 'suburbId' },
  street: { object: true, numberic: true, prop: 'streetId' },
  school: { object: true, numberic: true, prop: 'schoolId' },
  bathroom: { array: true, prop: 'bathrooms' }, // new v2
  carspace: { array: true, prop: 'carspaces' }, // new v2
  bedroom: { array: true, prop: 'bedrooms' }, // new v2
  price: {
    array: true,
    setter: ({ value, prop, itemSeprator }) => {
      if(!isArray(value)) {
        return prop
      }

      if(value.length === 1) {
        return `${prop}${itemSeprator}${value[0]}`
      }
      if(value.length === 2) {
        return `${prop}${itemSeprator}between-${value.join(',')}`
      }
      
      return ''
    },
    getter: ({ value }) => {
      // eslint-disable-next-line no-unused-vars
      const [_, startPrice, endPrice] = String(value).match(/(\d+),?(any|\d+)?/) || []
      const res = []
      
      startPrice && res.push(startPrice)
      endPrice && res.push(endPrice)

      return res
    },
  }, // new v2
  listTime: { numberic: true },
  checkListTime: { numberic: true }, // 有多少未浏览的新房源
  'listing-not-seen': { numberic: true }, // 有多少个新房源数量没有查看
  tag: { object: true, prop: 'tagId' },
  order: {},
  filters: {
    setter: filterSetter,
    getter: ({ value }) => extractValues(value)
  }, // new v2
  category: {},
  surrounding: { bool: true },
  page: { numberic: true },
}

/**
 * 新闻搜索
 */
export const newsSearchRules = {
  category: {},
  page: { numberic: true },
}

/**
 * 中介搜索
 */
// export const agentsRules = {
//   mode: {},
//   suburbId: {},
//   typeId: {},
//   categoryOption: {},
//   bedroomOption: {},
//   sellReasonOption: {},
//   order: {},
//   suburbName: {},
//   page: { numberic: true },
// }

export const agentsRules = {
  list: { array: true, apiDisabled: true, empty: true, },
  list1: { array: true, apiDisabled: true, empty: true },
  suburb: { array: true, object: true, numberic: true, prop: 'suburbId' },
  filters: {
    setter: filterSetter,
    getter: ({ value }) => extractValues(value)
  },
  page: { numberic: true },
}

/**
 * Productspec 搜索产品
 */
export const productSpecSearchRules = {
  tag: { array: true, object: true, numberic: true, prop: 'tagId' },
  company: { array: true, object: true, numberic: true, prop: 'companyIds' },
  files: { array: true, prop: 'filesExtension' },
  compliance: { array: true, prop: 'complianceType' },
  page: { numberic: true },
  limit: { numberic: true },
}

/**
 * Productspec Lives
 */
export const productSpecLiveRules = {
  page: { numberic: true }
}

/**
 * Productspec Cases
 */
export const productSpecCaseRules = {
  tag: { 
    array: true, object: true, numberic: true, prop: 'tagIds' 
  },
  company: { 
    array: true, object: true, numberic: true, prop: 'companyIds' 
  },
  page: { numberic: true },
}

/**
 * 区域分析搜索
 */
export const suburbSearchRules = {
  region: { array: true, object: true, numberic: true, prop: 'regionId' },
  district: { array: true, object: true, numberic: true, prop: 'districtId' },
  suburb: { array: true, object: true, numberic: true, prop: 'suburbId' },
  avm: { prop: 'soldAvm' },
  order: {},
  page: { numberic: true },
}
