import filterMetaJSON from '@lib/filter-meta.json'
import { forEach, isArray, pick, keys } from 'lodash-es'
import { addSuffixPrice, cateogryProps, priceProps } from '../lib'
import config from './config'

function getLabel({ label }) {
  return label.toLowerCase().replace(/[\s]*&[\s]*/g, '+').replace(/[\s]+/g, '-')
}

function getHouseFilterItems(data) {
  const { children, label, alias } = data || {}
  const tmp = {
    label,
    names: {},
    items: []
  }

  const isCategory = cateogryProps.indexOf(alias) > -1
  isCategory && (tmp.values = {})

  const target = isArray(children)
    ? priceProps.indexOf(alias) > -1
      ? addSuffixPrice(children)
      : children
    : []
  
  forEach(target, (item) => {
    const labelKey = isCategory ? getLabel(item) : item.label

    tmp.names[item.value] = labelKey
    tmp.items.push(pick(item, ['label', 'value']))

    if(isCategory) {
      tmp.values[labelKey] = item.value
    }
  })
  
  return tmp
}

/**
 * 返回的数据结构如下
 * {
 *    barn: {
 *      items: [{label, value}, {label, value}, ....],
 *      label: "",
 *      names: {[value]: [label], [value]: [label], ....},
 *    },
 *    ...
 * },
 */
function getFilterParams() {
  const tmp = {}

  forEach(filterMetaJSON, (item) => {
    tmp[item.alias] = getHouseFilterItems(item)
  })
 
  return tmp
}

function valueForward(data) {
  const tmp = {}
  forEach(data, (value, key) => tmp[value] = key)

  return tmp
}

function parseFilterConfig(cfg) {
  forEach(keys(cfg), (key) => {
    const item = cfg[key]
    item.fullname = valueForward(item.alias)
    item.specialFullname = valueForward(item.special)
  })

  return cfg
}

export const filterMeta = getFilterParams()
export const filterConfig = parseFilterConfig(config)
