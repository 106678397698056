/**
 * 腾讯云 SDKAppId
 *
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */
export const SDKAPPID = 1400340261

//
export const DEFAULT_HTML_FONT_SIZE = 20

/**
 * 接口地址
 */
export const API_BASE_CLIENT = process.env.NEXT_PUBLIC_API_CLIENT
export const API_BASE_SERVER = process.env.NEXT_PUBLIC_API_SERVER

/**
 * Api version
 */
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION

/**
 * Public token
 */
export const PUBLIC_TOKEN = 'Public QjQxbjczaXZiay13MFc4T3lFa20xLXdobW5FOXc2NmU6cHM0ejFhNGM1Si1OcERjNnVqWDY3LVlOeUJnWDhEN28='

/**
 * admin地址
 */
export const ADMIN_BASE_URL = process.env.NEXT_PUBLIC_ADMIN_URL

/**
 * websocket url
 */
export const WEB_CHART_URL = process.env.NEXT_PUBLIC_WEBSOCKET_URL

// domain
export const domain = process.env.NEXT_PUBLIC_COOKIE_TOKEN_DOMAIN

const prefix = process.env.NEXT_PUBLIC_COOKIE_TOKEN_PREFIX || ''
const contry = process.env.NEXT_PUBLIC_COUNTRY

export const TOKEN_NAME = `${prefix}HOUGARDEN_TOKEN`
export const REFRESH_TOKEN_NAME = `${prefix}HOUGARDEN_REFRESH_TOKEN`
export const UUID_PROP = `${prefix}User-uuid`
export const ENQUIRY_FORM_PARAM = `${prefix}enquiry_form_param`
export const HG_LISTING_MODE = `${prefix}HG_LISTING_MODE_${contry}`
export const HG_CURRENT_COUNTRY_NAME = `${prefix}HG_CURRENT_COUNTRY_NAME`

export const HOUGARDEN_LOCATIONS_DATA = `${prefix}HOUGARDEN_LOCATIONS_DATA_${contry}`
export const HOUGARDEN_FIRE_EVENT_LOCATIONS_MAP = 'HOUGARDEN_FIRE_EVENT_LOCATIONS_MAP'
export const HG_USER_KEY = `${prefix}HG_USER_KEY`
export const HG_ENABLED_MOVE_RESEARCH = `${prefix}HG_ENABLED_MOVE_RESEARCH`
export const HG_SEARCH_RECORDING_STATE = `${prefix}HG_SEARCH_RECORDING_STATE_V7_${contry}`
export const HG_CURRENT_REGION_INFO = `${prefix}HG_CURRENT_REGION_INFO_${contry}`
export const HG_NEXTTIME_BIND_EMAIL = `${prefix}HG_NEXTTIME_BIND_EMAIL${contry}`

/**
 * 用户角色
 */
export const roles = {
  ANONYMOUS: 0,
  NORMAL: 1,
  MATER: 2,
  MANAGER: 3,
}

/**
 * 房源类型
 */
export const propertyType = {
  SOLD: -99,
  ESTIMATE: -100,
  RESIDENTIAL: 1,
  COMMERCIAL_FOR_SALE: 2,
  COMMERCIAL_FOR_LEASE: 6,
  RURAL: 3,
  RENTAL: 5,
  // PERSONAL_RENTAL: -1,
}

/**
 * 房源搜索类型
 */
export const houseSearchType = {
  property: 'buy',
  residential: 'buy',
  rent: 'rent',
  rental: 'rent',
  newHomes: 'new-builds',
  estimate: 'estimate',
  development: 'for-developers',
  sold: 'sold',
  rural: 'rural',
  commercialForSale: 'commercial-for-sale',
  commercialForLease: 'commercial-for-lease',
}

// 房屋类型所对应的typeId
export const typeIds = {
  [houseSearchType.residential]: propertyType.RESIDENTIAL,
  [houseSearchType.rent]: propertyType.RENTAL,
  [houseSearchType.newHomes]: propertyType.RESIDENTIAL,
  [houseSearchType.estimate]: propertyType.ESTIMATE,
  [houseSearchType.development]: propertyType.RESIDENTIAL,
  [houseSearchType.sold]: propertyType.SOLD,
  [houseSearchType.rural]: propertyType.RURAL,
  [houseSearchType.commercialForSale]: propertyType.COMMERCIAL_FOR_SALE,
  [houseSearchType.commercialForLease]: propertyType.COMMERCIAL_FOR_LEASE,
}

// 房源类型id所对应的type名称
export const typeOfId = {
  [propertyType.RESIDENTIAL]: houseSearchType.residential,
  [propertyType.RENTAL]: houseSearchType.rent,
  // [propertyType.PERSONAL_RENTAL]: houseSearchType.rent,
  [propertyType.ESTIMATE]: houseSearchType.estimate,
  [propertyType.SOLD]: houseSearchType.sold,
  [propertyType.RURAL]: houseSearchType.rural,
  [propertyType.COMMERCIAL_FOR_SALE]: houseSearchType.commercialForSale,
  [propertyType.COMMERCIAL_FOR_LEASE]: houseSearchType.commercialForLease,
}

// 新闻内容类型
export const articleContentType = {
  ad: 'ad-tag',
  nbs: 'nbs-tag',
  office: 'office-tag',
  agent: 'agent-tag',
  house: 'house-tag',
  motor: 'motor-tag',
  estimate: 'estimate-tag',
  sold: 'sold-tag',
  knowledgeTopic: 'knowledge-topic-tag',
  knowledgeNews: 'knowledge-news-tag',
  marketplace: 'marketplace-tag',
  recommendHouse: 'recommend-house-tag',
  recommendMotor: 'recommend-motor-tag',
  live: 'live-tag',
  opencourse: 'opencourse-tag',
  groceriesDealer: 'groceries-dealer-tag',
  groceriesListing: 'groceries-listing-tag',
  specialEvent: 'special-event-tag',
  specialList: 'special-list-tag',
  vote: 'vote-tag',
  feed: 'feed-tag',
  groceriesVoucher: 'groceries-voucher-tag',
  listingCarousel: 'listing-carousel-tag',
  article: 'article-tag',
  groceriesDealerCoupons: 'groceries-dealer-coupons-tag',
  saleDiscount: 'sale-discount-tag',
  text: 'text-tag',
  searchBar: 'search-bar-tag'
}

// event types
export const eventTypes = {
  openCourse: 'openCourse',
  live: 'live',
  simpleLive: 'simpleLive'
}

// 直播状态
export const liveStatus = {
  notStart: 1,
  living: 2,
  end: 3
}

// 国家简写
export const countryAlias = {
  nz: 'NZ',
  au: 'AU',
}

// 电话前缀
export const phonePrefix = [
  { label: { en: 'China', zh: '中国' }, value: '+86' },
  { label: { en: 'New Zealand', zh: '新西兰' }, value: '+64', country: countryAlias.nz },
  { label: { en: 'Malaysia', zh: '马来西亚' }, value: '+60' },
  { label: { en: 'Australia', zh: '澳大利亚' }, value: '+61', country: countryAlias.au },
  { label: { en: 'China - Taiwan', zh: '中国台湾' }, value: '+886' },
  { label: { en: 'China - Hong Kong', zh: '中国香港' }, value: '+852' },
  { label: { en: 'Singapore', zh: '新加坡' }, value: '+65' },
  { label: { en: 'America', zh: '美国' }, value: '+1' }
]

/**
 * 地图默认坐标
 */
export const mapboxDefaultCoords = {
  [countryAlias.nz]: { longitude: 174.76093750757872, latitude: -36.85819313632921, zoom: 13 },
  [countryAlias.au]: { longitude: 151.2189859, latitude: -33.8553325, zoom: 13 },
}
export const mapboxDefaultCoord = mapboxDefaultCoords[process.env.NEXT_PUBLIC_COUNTRY]

// 时区
export const timeZones = {
  [countryAlias.nz]: 'Pacific/Auckland',
  [countryAlias.au]: 'Australia/Sydney',
}
export const timeZone = timeZones[process.env.NEXT_PUBLIC_COUNTRY]

/**
 * web地址
 */
export const baseUrls = {
  [countryAlias.nz]: process.env.NEXT_PUBLIC_BASE_WEB_URL_NZ,
  [countryAlias.au]: process.env.NEXT_PUBLIC_BASE_WEB_URL_AU
}
export const WEB_BASE_URL = baseUrls[process.env.NEXT_PUBLIC_COUNTRY]

// 聊天缓存消息
export const CHAT_CACHE_MESSAGE = `${prefix}CHAT_CACHE_MESSAGE`

// mapbox token and style
const mapboxTokens = {
  [countryAlias.nz]: process.env.NEXT_PUBLIC_MAPBOX_ACCESSTOKEN,
  [countryAlias.au]: process.env.NEXT_PUBLIC_MAPBOX_ACCESSTOKEN_AU,
}
const mapboxStyles = {
  [countryAlias.nz]: 'mapbox://styles/hougarden/cjs4309860av11fpp800hs3s6',
  [countryAlias.au]: 'mapbox://styles/oneroof/clt80jz9y00i801o85z2e1d7m',
}

export const mapboxToken = mapboxTokens[process.env.NEXT_PUBLIC_COUNTRY]
export const mapboxStyle = mapboxStyles[process.env.NEXT_PUBLIC_COUNTRY]

// 新闻评论类型
export const commentTypes = {
  public: 0,
  loginRequired: 1,
  close: 2
}
