import { houseSearchType, typeIds } from '@lib/config'
import { getApiSearchParam, config, modeType } from '@lib/parse'
import { omit, forEach, isArray, groupBy, filter, map, last } from 'lodash-es'
import { filterConfig, filterMeta } from './meta'

/**
 * 获取已选中的数据
 */
export function getCheckedItems(data, init = false) {
  const tmp = {}
  data.forEach(({ alias, children }) => {
    tmp[alias] = init ? [] : map(
      filter(children, (f) => f.selected),
      'value'
    )
  })

  return tmp
}

/**
 * 生成filter参数的值
 */
export function getFilterValue(checked) {
  const tmp = []
  forEach(checked, (val, key) => {
    val.length > 0 && tmp.push(`${key}_${val.join(',')}`)
  })

  return tmp.join('_')
}

/**
 * 获取数量
 * @param {Object} data 接口返回的数据
 * @returns {Object}
 */
export function getCount(data) {
  const d = data || {}
  const selected = d['x-selected-count']
  const total = d['x-total-count']
  
  return {
    selected, 
    total,
    current: selected || total || 0
  }
}

/**
 * 根据类型返回 filter 接口请求参数
 * type 为 config.js -> houseSearchType 中对应的 值
 */
export function getParamByType(type) {
  const param = {
    typeId: typeIds[type]
  }

  if(type === houseSearchType.newHomes) {
    param.newHouse = 1
  } else if(type === houseSearchType.development) {
    param.dataType = 3
  }

  return param
}

/**
 * 将数组转换成分组的数据
 * [{label: '', value: '', type: 'suburb'}, ...]
 *  => { suburb: [{label: '', value: '', type: 'suburb'}, ...], ... }
 */
export function getGroupedData(data, rules = config.houseSearchRules) {
  const groupedData = groupBy(data, (t) => t.type)
  const tmp = {}

  forEach(groupedData, (val, key) => {
    const rule = rules[key]
    tmp[key] = !rule?.array && isArray(val) ? val[0] : val
  })

  return tmp
}

/**
 * 生成接口请求需要的参数
 * @param {Array} data [{label: '', value: '', type: ''}, ''']
 */
export function getSearchApiParam(data, searchParam) {
  const p = getApiSearchParam(
    getGroupedData(data), 
    config.houseSearchRules
  )

  if(searchParam?.filter) {
    p.filter = searchParam.filter
  }
  
  return omit(p, ['offset', 'limit'])
}

/**
 * 没有价格区间的数据添加结尾
 * 比如: {label: '10000000', num: 19} => {label: '10000000-any', num: 19}
 */
export function addSuffixPrice(options) {
  return map(options, (opt) => ({
    ...opt,
    label: opt.label.indexOf('-') === -1 ? `${opt.label}-any` : opt.label
  }))
}

/**
 * 获取价格范围的开始值与结束值
 */
export function getPriceRange(options, [start, end]) {
  const optLen = options.length - 1
  const [minPrice] = String(options[start]?.label).split('-')
  
  // const endIndex = (end - 1) < 0 ? 0 : (end - 1) > optLen ? optLen : (end - 1)
  const endIndex = end < 0 ? 0 : end > optLen ? optLen : end
  const [, endMaxPrice] = String(options[endIndex]?.label).split('-')
  
  return [
    parseFloat(minPrice),
    /\d+/.test(endMaxPrice) ? parseFloat(endMaxPrice) : endMaxPrice
  ]
}

export function getPriceIds(alias, val, target) {
  if(val === undefined) {
    return ''
  }
  
  const [startPrice, endPrice] = String(val).split(',')
  const result = []
  let isStart = false

  forEach(target, (v, key) => {
    const [start, end] = String(v).split('-')
    if(startPrice === start) {
      isStart = true
    } else if(endPrice && start === endPrice) {
      isStart = !end
    }
    
    if(isStart) {
      result.push(key)
    }
  })
  
  return result.length > 0
    ? `${alias}_${result.join(',')}`
    : ''
}

// ==========================================================================
// 与 filter 参数相关

// 与价格有关的 filter参数
export const priceProps = ['prn', 'prrn', 'crn', 'prcsn', 'prcln']
// 与卧室有关的 filter参数
export const bedroomsProps = ['bern']
// 与浴室有关的 filter参数
export const bathroomsProps = ['barn']
// 与车位有关的 filter参数
export const carspaceProps = ['pkn']
// 与房源类型有关的 filter参数
export const cateogryProps = ['ptn', 'ptrn', 'ptcsn', 'ptcln', 'ptrun']
// 与城市规划有关的 filter参数
export const planProps = ['utz']
// 与政府cv有关的 filter参数
export const cvProps = ['crn', 'cv']

// 需要渲染成slider 
export function arePriceSlider(alias) {
  return priceProps.indexOf(alias) > -1
}

/**
 * 通过filter参数生成url链接的对象
 */
export function getFilterObject({
  type,
  searchParam,
  defaultValue = [], 
  checked = {}, 
}) {
  const tmp = getGroupedData(defaultValue)
  const filters = []
  let categoryName
  
  // category
  forEach(modeType, (key) => {
    if(isArray(searchParam[key])) {
      tmp[key] = searchParam[key]
      categoryName = key
    }
  })
  
  const { specialFullname, fullname } = filterConfig[type] || {}
  forEach(checked, (val, alias) => {
    const propName = specialFullname[alias]
    
    if(propName === 'category' && isArray(val)) {
      const metaNames = filterMeta[alias]?.names
      tmp[categoryName] = map(val, (v) => metaNames?.[v])
    }

    if(val?.length === 0) return
    
    if(propName) {
      // 价格
      if(propName === 'price') {
        const priceArray = filterMeta[alias]?.items || []
        const priceIds = map(priceArray, 'value')
        
        tmp.price = getPriceRange(priceArray, [
          priceIds.indexOf(val[0]),
          priceIds.indexOf(last(val)),
        ])
      } else {
        tmp[propName] = val
      }
    } else {
      filters.push({ label: fullname[alias] || alias, value: val.join(',') })
    }
  })

  if(searchParam.surrounding) {
    tmp.surrounding = searchParam.surrounding
  }

  if(filters.length > 0) {
    tmp.filters = filters
  }
  
  return tmp
}
