import { defaultLocale } from '@i18n/config'
import { WEB_BASE_URL } from '@lib/config'
import { isValid } from '@lib/utils'
import { isObject } from 'lodash-es'

/**
 * 获取路由名称
 * @param {String} routeUrl 路由地址
 * @param {Object} params 路由地址中要替换的参数
 * @param {String} lang 多语言配置
 * 
 * @example
 *   getRouteUrl('/check-in/send-files/[houseId]', {houseId: 1})
 *   // /check-in/send-files/1
 * 
 * @return {String}
 */
export function getRouteUrl(routeUrl, params, lang, baseUrl) {
  if (!routeUrl) {
    throw new Error('The route name does not exist')
  }

  const realeBaseUrl = baseUrl || WEB_BASE_URL
  const locale = (lang === undefined || lang === defaultLocale || lang === '') ? '' : `/${lang}`

  let url = routeUrl
  const matchParams = routeUrl.match(/\{([\w$]+)\}/g)

  if (matchParams === null || !isObject(params)) {
    return `${realeBaseUrl}${locale}${url}`
  }

  if (matchParams) {
    matchParams.forEach((item) => {
      const prop = item.slice(1, item.length - 1)

      if (isValid(params[prop])) {
        url = url.replace(item, params[prop])
      }
    })
  }

  return `${realeBaseUrl}${locale}${url}`
}
