import { createSharedPathnamesNavigation } from 'next-intl/navigation'

export const defaultLocale = 'zh'

export const enAcceptLanguage = [
  'en',
  'en-us',
  'en-gb',
  'en-ca',
  'en-au',
  'en-nz',
  'en-ie',
  'en-za',
  'en-jm',
  'en-bz',
  'en-tt',
  'en-zw',
  'en-ph',
  // 印度排除，因为有专用语音
  // 'en-in',
  'en-my',
  'en-sg',
  'en-hk',
  'en-mo',
  'en-pk',
  'en-bd'
]

/**
 *【*】这里的顺序不要变，否则可能出现潜在的错误
 *【*】代码里面的有些判断是根据数组下标来获取值
 */
export const locales = [
  defaultLocale, 
  'en',
  'zh-tw',
  'zh-hk',
  'mi',
  'ja',
  'ko',
  'es',
  'de',
  'fr',
  'ar',
  'tl',
  'hi',
  'vi',
  'th',
  'gu',
  'pa',
]

export const langOptions = [
  { label: 'English', value: locales[1] },
  { label: '中文简体', value: defaultLocale },
  { label: '繁體中文(台灣)', value: locales[2] },
  { label: '繁體中文(香港)', value: locales[3] },
  { label: 'Te Reo Māori', value: locales[4] },
  { label: 'हिन्दी(Hindi)', value: locales[12] },
  { label: 'Gujarati', value: locales[15] },
  { label: 'Punjabi', value: locales[16] },
  { label: 'Tagalog', value: locales[11] },
  { label: 'Tiếng Việt', value: locales[13] },
  { label: '日本語(にほんご)', value: locales[5] },
  { label: '한국어(조선말)', value: locales[6] },
  { label: 'ภาษาไทย(Phasa Thai)', value: locales[14] },
  { label: 'العربية(Al-‘Arabīyah)', value: locales[10] },
  { label: 'Deutsch', value: locales[8] },
  { label: 'Español', value: locales[7] },
  { label: 'Français', value: locales[9] },
]

export const localCookieName = 'NEXT_LOCALE_HOUGARDEN'

export function chineseCheck(lang) {
  return [locales[0], locales[2], locales[3]].indexOf(lang) > -1
}

export const { 
  Link, 
  redirect,
  usePathname, 
  useRouter
} = createSharedPathnamesNavigation({ locales })
