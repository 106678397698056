import { locales } from '@i18n/config'
import { baseUrls, countryAlias, houseSearchType, phonePrefix, propertyType } from '@lib/config'
import { Base64 } from 'js-base64'
import Cookie from 'js-cookie'
import {
  capitalize,
  cond, constant,
  forEach,
  get,
  isArray,
  isFunction,
  isNaN,
  isObject,
  isString,
  join,
  keys,
  map,
  omit, pick,
  size, values
} from 'lodash-es'

// 是否是浏览器环境
export const isWindow = typeof window !== 'undefined'

// 是否为澳洲
export const isAU = process.env.NEXT_PUBLIC_COUNTRY === countryAlias.au
// 是否为新西兰
export const isNZ = process.env.NEXT_PUBLIC_COUNTRY === countryAlias.nz

// 获得默认国家码
export function getPhonePrefix() {
  let dpp = '+64'
  for (let i = 0, len = phonePrefix.length; i < len; i++) {
    if (phonePrefix[i].country === process.env.NEXT_PUBLIC_COUNTRY) {
      dpp = phonePrefix[i].value
      break
    }
  }

  return dpp
}

/**
 * 传入国家字符串
 *  - 如果是在当前国家，则返回null
 *  - 否则返回下面的 base url
 * 
 * @param {String} country // NZ or AU
 * @returns {null | String}
 */
export function countryTest(country) {
  const result = values(countryAlias).indexOf(country) > -1
    && process.env.NEXT_PUBLIC_COUNTRY === country

  return result ? null : baseUrls[country]
}

/**
 * 检测屏幕宽度
 * @returns {Boolean}
 */
export function isMobile() {
  return isWindow
    ? window.document.body.clientWidth < 768
    : false
}

/**
 * 检测数据是否有效值
 *  - 返回false: 空字符串, null, NaN, undefined
 */
export function isValid(val) {
  if (isString(val)) {
    return val.trim() !== ''
  }

  return val !== undefined
    && val !== null
    && !Number.isNaN(val)
}

/**
 * 将对象中值空字符串、null、NaN、undefined字段移除
 */
export function clean(obj) {
  const tmp = Object.create(null)
  map(
    keys(isObject(obj) ? obj : {}),
    (key) => isValid(obj[key]) && (tmp[key] = obj[key]),
  )
  return tmp
}

/**
 * 保存信息到cookie
 * @param {Any} value 要保存的信息
 * 
 */
export function cookieSet(key, value, options = {}) {
  const tmp = isObject(value)
    ? JSON.stringify(value)
    : value

  Cookie.set(key, tmp, options)
}

/**
 * Cookie.get
 * 
 * @param {String} key key
 * @param {Boolean} returnObject 是否返回 JSON.parse数据
 */
export function cookieGet(key, returnObject) {
  let result = null

  try {
    const val = Cookie.get(key)
    if (val !== undefined) {
      result = returnObject ? JSON.parse(val) : val
    }
  } catch (err) {
    //
  }

  return result
}

/**
 * 转换坐标
 * [[{lng: 174.76081567, lat: -36.86834371}, ...]]
 * // [[[174.76081567, -36.86834371], ....]]
 */
export function objectCoordToArray(data) {
  if (!isArray(data)) return []
  return map(data, (item) => {
    if (isArray(item)) {
      return objectCoordToArray(item)
    }

    if (isObject(item)) {
      return [item.lng, item.lat]
    }

    return item
  })
}

/**
 * 移除小数结尾的 0
 */
export function removeTrailingZeros(number) {
  const numStr = String(number)
  if (!numStr.includes('.')) {
    return numStr
  }

  const [integerPart, decimalPart] = numStr.split('.')
  const decimal = decimalPart.replace(/0+$/, '')

  return decimal === ''
    ? integerPart
    : `${integerPart}.${decimal}`
}

/**
 * 格式化价格
 * 
 * @param {Number|String} price 价格
 * @param {Boolean} conversion 是否需要换算(k, m)
 * @param {Number} decimal 保存小数位数
 */
export function formatPrice(
  price,
  conversion = false,
  decimal = 2
) {
  const canConversion = /\d+/.test(price)
  let target = String(price)
  let toMillions = false
  let toThousand = false
  let unit = ''

  if (conversion && canConversion) {
    target = parseFloat(price)
    toMillions = target >= 1000000
    toThousand = target > 1000

    target = toMillions
      ? (target / 1000000).toFixed(decimal)
      : toThousand
        ? (target / 1000).toFixed(decimal)
        : target

    unit = toMillions ? 'm' : (toThousand ? 'k' : '')
    target = removeTrailingZeros(target)
  }

  return canConversion
    ? `${target.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${unit}`
    : price
}

// 去掉所有的html标记
export function clearHtmlTag(str) {
  return String(str).replace(/<[^>]+>/g, '')
}

/**
 * 是否是详情页
 */
export function isDetailSlug(slug) {
  return /[-_]+/g.test(slug)
}

// 解析URL的查询参数并返回一个对象
export function parseURLSearchParams(str) {
  const params = {}
  if (!str) return params

  const searchParams = str?.entries ? str : new URLSearchParams(str)
  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }

  return params
}

/**
 * 组装查询参数对象为URL查询字符串
 */
export function buildURLSearchParams(params) {
  const searchParams = new URLSearchParams()
  forEach(params, (value, key) => {
    if (Reflect.has(params, key)) {
      searchParams.append(key, value)
    }
  })

  return searchParams.toString()
}

/**
 * 拼装新的url
 * 
 * @param {Object} searchParams
 * @param {String} baseUrl
 * @param {String} hashString
 */
export function generateUrl(baseUrl = '', searchParams = {}, hashString = '') {
  const url = [baseUrl]
  const query = buildURLSearchParams(searchParams)

  if (query) {
    url.push(`?${query}`)
  }
  if (hashString) {
    url.push(`#${hashString}`)
  }

  return url.join('')
}

/**
 * 修改当前URL
 * 
 * @param {Object} searchParams
 * @param {String} hashString
 * @param {Boolean} replace // 是否用searchParams 替换所有参数
 */
export function rebuildUrl(searchParams = {}, hashString = '', replace = false) {
  if (!isWindow) return ''

  const { origin, pathname, search, hash } = window.location
  const baseURL = `${origin}${pathname}`
  const newHash = hashString || hash
  const url = new URL(baseURL)

  const param = replace ? searchParams : {
    ...parseURLSearchParams(search),
    ...searchParams
  }

  if (param) {
    Object.keys(param).forEach((key) => {
      url.searchParams.set(key, param[key])
    })
  }

  if (newHash) {
    url.hash = newHash
  }

  return url.toString()
}

/**
 * 获得类别名称
 * tm = useTranslations('menu')
 * ts = useTranslations('search')
 */
export function getCategoryName(type, tm) {
  const names = {
    [houseSearchType.residential]: tm('houseForSale'),
    [houseSearchType.rent]: tm('rental'),
    [houseSearchType.newHomes]: tm('newHouse'),
    [houseSearchType.estimate]: tm('estimate'),
    [houseSearchType.development]: tm('development'),
    [houseSearchType.sold]: tm('sold'),
    [houseSearchType.rural]: tm('rural'),
    [houseSearchType.commercialForSale]: tm('commercialForSale'),
    [houseSearchType.commercialForLease]: tm('commercialForLease'),
    agents: tm('agent'),
  }

  return names[type]
}

export function openCenteredWindow(url, title, w, h) {
  const left = (window.innerWidth - w) / 2
  const top = (window.innerHeight - h) / 2

  const options = `
    width=${w},
    height=${h},
    top=${top},
    left=${left},
    toolbar=no,
    location=no,
    directories=no,
    status=no,
    menubar=no,
    scrollbars=no,
    resizable=no,
    copyhistory=no
  `

  const newWindow = window.open(url, title, options)

  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}

/**
 * 替换字符串中的参数
 * 主要用于生成seo相关参数(title, keywords...)
 * 
 * eg:
 *   target: {
 *     title: "{street}, {suburbName}",
 *     keywords: "{street}, 后花园"
 *   }
 *   data: { "street": "21a qq st", "suburbName": "Epsom" }
 * 
 *   // { "title": "21a qq st, Epsom", "keywords": "21a qq st, 后花园" }
 */
export function getSeoData(target, data = {}, kyes = ['title', 'keywords', 'description']) {
  const result = {}
  const seoTarget = pick(target, kyes)
  for (const key in seoTarget) {
    if (Reflect.has(seoTarget, key)) {
      let tmp = String(seoTarget[key])

      for (const variable in data) {
        if (Reflect.has(data, variable)) {
          const replacement = isValid(data[variable]) ? data[variable] : ''
          tmp = tmp.replace(
            new RegExp(`{${variable}}`, 'g'),
            replacement
          )
        }
      }
      result[key] = tmp
    }
  }

  return result
}

/**
 * 获取 header 中 alternates 配置文件
 * @param {Function} fn 生成url链接的函数
 * @param {String} locale 当前语言（zh, en...)
 * @param {Object} option 配置
 */
export function getAlternates(fn, locale, option) {
  const {
    alternateLink = true, // 是否开启多语言提醒
    languageCallback, // 有时候 alternate链接与 canonical链接不一致
  } = option || {}

  const languages = {}
  if (alternateLink) {
    const callback = isFunction(languageCallback) ? languageCallback : fn
    map(locales, (lang) => languages[lang] = callback(lang))
  }

  return {
    alternates: {
      canonical: fn(locale),
      languages
    }
  }
}

/**
 * slug转id
 * @param {string} slug slug
 * @returns id
 */
export function slugToId(slug) {
  return slug ? `${slug}`.split('-').pop() : slug
}

/**
 * slug转名字 首字母大写
 * @param {string} slug slug
 * @returns slug name
 */
export function slugToName(slug) {
  const str = `${slug}`.replace(/-\d+$/, '')
  return join(map(str.split('-'), (item) => capitalize(item)), ' ')
}

/**
 * 将字符串转换成slug(去掉空格、斜线、逗号)
 */
export function toSlug(inputString) {
  return String(inputString).replace(/[\s\\/,]+/g, '-').toLowerCase()
}

/**
 * 通过传入 nextjs的参数 searchParams
 * 返回当前页；分页参数都数据
 */
export function initialSearchParams(searchParams, limit = 10) {
  const defaultPage = /\d+/.test(searchParams?.page)
    ? parseInt(searchParams?.page, 10)
    : 1

  const option = { swrOptions: { revalidateIfStale: true } }
  const param = {
    limit,
    offset: (defaultPage - 1) * limit,
    ...omit(searchParams, ['page'])
  }

  return {
    defaultPage,
    pageSize: limit,
    param,
    option,
  }
}

/**
 * 房源列表搜索 filter ,将其转换成 object
 * @param {String} filterStr 
 * @returns 
 */
export function getFilterObjectData(filterStr) {
  const tmp = {}

  if (!filterStr) {
    return tmp
  }

  const arr = filterStr.split('_')
  let i = 0
  const len = arr.length

  for (; i < len; i++) {
    tmp[arr[i]] = arr[++i]
  }

  return tmp
}

/**
 * 判断是否为微信访问
 */
export function isWechat() {
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
  // 由此来判断是否为微信浏览器
  return isWindow && Boolean(window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i))
}

export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

/**
 * google广告,取出广告参数
 * @param {string} code `
 * <div id='ad_banner' data-adsize="[[970,250],[728,90]]" data-adunit="leaderboard" data-adpos="1"/>
 * `
 * @returns {adSize, adUnit, adPos, id}
 */
export function codeToParams(code) {
  const adSize = get(code.match(/data-adsize="(.+?)"/), '1', '')
  const adUnit = get(code.match(/data-adunit="(.+?)"/), '1', 'rectangle')
  const adPos = get(code.match(/data-adpos="(.+?)"/), '1')
  const id = get(code.match(/id="(.+?)"/), '1')
  return { adSize, adUnit, adPos, id }
}

/**
 * 根据house获取gta数据
 * @param {Object} houses 
 * @returns 
 */
export function houseToGta(houses, pageType, type = 'detail') {
  if (!houses || !size(houses)) return null

  if (isArray(houses)) {
    // eslint-disable-next-line no-use-before-define
    return map(houses, (house) => getParams(house, pageType, type))
  }
  // eslint-disable-next-line no-use-before-define
  return getParams(houses, pageType, type)
}

function getParams(house, pageType, type) {
  const houseTypeId = cond([
    [constant(type === 'detail'), constant(get(house, 'typeId'))],
    [constant(type === 'ad_house'), constant(get(house, 'type.id'))],
    [constant(type === 'search_result'), constant(get(house, 'type.id') || get(house, 'typeId'))],
    [constant(true), constant(get(house, 'typeId'))]
  ])()
  // eslint-disable-next-line camelcase
  const pagetype = pageType || cond([
    [constant(houseTypeId === propertyType.RESIDENTIAL), constant('for_sale_listing_details')],
    [constant(houseTypeId === propertyType.RENTAL), constant('for_rent_listing_details')],
    [constant(houseTypeId === propertyType.SOLD), constant('sold_listing_details')],
    [constant(true), constant('for_sale_listing_details')]
  ])()

  const officeId = get(house, 'office_external_id') || get(house, 'officeExternalId', '')
  const listingId = get(house, 'external_id') || get(house, 'externalId', '')
  const agents = get(house, 'agents') || get(house, 'simple_agents') || []
  const aggentsId = map(agents, (item) => item.agent_external_id || item.agentExternalId).join(',')

  switch (type) {
    case 'detail':
      return {
        office_id: officeId,
        agent_id: aggentsId,
        listing_id: listingId,
        page_type: pagetype
      }
    case 'ad_house':
      return {
        office_id: get(house, 'offices[0].office_external_id') || get(house, 'offices[0].officeExternalId'),
        agent_id: aggentsId,
        listing_id: listingId,
        page_type: pagetype
      }
    case 'search_result':
      return {
        office_id: officeId,
        agent_id: aggentsId,
        listing_id: listingId,
        page_type: pagetype
      }
    default:
      return null
  }
}

/**
 * 给html内的img标签加一个alt，如果存在alt属性则不添加
 * @param {*} htmlContent 
 * @param {*} alt 
 * @returns 
 */
export function htmlImgAddAlt(htmlContent, alt = 'hougarden') {
  try {
    const reg = /<img(?![^>]*\balt="[^"]*")[^>]*>/gi
    return htmlContent.replace(reg, (match) => match.replace(/\/?>/, ` alt="${alt}" />`))
  } catch (error) {
    return htmlContent
  }
}

export function getCount(n) {
  if (n < 1000) return n.toString()
  if (n < 5000) return '5,000+'

  const res = Math.ceil(n / 5000) * 5000
  return isNaN(res) ? '-' : `${res.toLocaleString()}+`
}

/**
 * 调用app方法
 * @param {string} name 
 * @param {object} params 
 */
export function callNative(name, params) {
  if (params instanceof Object) params = JSON.stringify(params)
  if (
    window.webkit
    && window.webkit.messageHandlers
    && window.webkit.messageHandlers.nativeCallback
  ) {
    window.webkit.messageHandlers.nativeCallback.postMessage([name, params ? Base64.encode(params) : ''])
  } else if (window.callBackFormJs && window.callBackFormJs[name]) {
    if (params) {
      window.callBackFormJs[name](Base64.encode(params))
    } else {
      window.callBackFormJs[name]()
    }
  } else if (window[name] && window[name].postMessage) {
    // 只能接受到一个参数
    if (params) {
      window[name].postMessage(Base64.encode(params))
    } else {
      window[name].postMessage('')
    }
  }
}

/**
 * 接收app方法
 * @param {string} name 
 * @returns promise
 */
export function receiveNative(name, callback) {
  return new Promise((resolve) => {
    window[name] = function (data) {
      const res = data ? JSON.parse(Base64.decode(data)) : undefined
      resolve(res)
      callback(res)
    }
  })
}
